<template>
  <div class="carousel_section">
    <div class="show_all_link">
      <router-link to="/company">Show All Companies </router-link>
    </div>
    <Carousel
      :autoplay="10000"
      :breakpoints="breakpoints"
      :wrapAround="true"
      :transition="500"
    >
      <Slide v-for="company in companies" :key="company.id">
        <router-link to="/company">
          <div class="carousel__item">
            <div class="carousel_img">
              <img :src="`https://api.landstory-pl.com/${company.image_Path}`" alt="" />
            </div>
            <div class="carousel_title">
              <h3>{{ company.nameCompany }}</h3>
            </div>
          </div>
        </router-link>
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "CompanyComponent",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props:{
    companies: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        400: {
          itemsToShow: 1,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
        },
      },
    };
  },
});
</script>

<style scoped>
.show_all_link {
  display: flex;
  justify-content: end;
  padding: 5px 45px;
}

.show_all_link a {
  text-decoration: none;
  color: rgb(91, 195, 255);
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
}

.show_all_link a:hover {
  color: rgb(25, 171, 255);
}

.carousel_section {
  width: 100%;
  margin: 50px auto;
}

.carousel__slide {
  padding: 15px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

.carousel__item {
  width: 350px;
  height: 200px;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}

.carousel__item .carousel_img {
  width: 100%;
  height: 100%;
}

.carousel_img img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
}

.carousel__item .carousel_title {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  padding: 15px 25px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.888) 5%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 3px 7px 5px black;
  text-align: start;
}

.carousel_title h3 {
  position: absolute;
  bottom: 25px;
  color: white;
  font-size: 24px;
}


@media (max-width:1024px){
  
}


@media (max-width:500px){
  .carousel_section {
  width: 100%;
  margin: 20px auto;
}
  .show_all_link {
  padding: 5px 25px;
}

.show_all_link a {
  font-size: 16px;
}
}
</style>
