<template>
  <img src="@/assets/palestine.png" alt="Main Image" class="main_img">
  <Navbar />
  <router-view/>
  <FooterComponents />
</template>

<script>
import Navbar from '@/components/NavBar.vue'
import FooterComponents from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    FooterComponents
  },
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  background-color: #35393B;
  min-height: 100vh;
}

.main_img{
  display: block;
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background: #ff2f2f50;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #ff2f2f7a;
  border-radius: 10px;
}
</style>
