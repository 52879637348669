import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ArticlesView from '@/views/ArticlesView.vue'
import SingleArticle from '@/views/SingleArticle.vue'
import AllCompany from '@/views/AllCompany.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/articles',
    name: 'articles',
    component: ArticlesView
  },
  {
    path: '/article/:id',
    name: 'article',
    component: SingleArticle
  },
{
  path: '/company',
  name: 'company',
  component: AllCompany
}
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to,) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
    return { top: 0 };
  }
});

export default router
