<template>
  <div style="width: 90%;">
    <div v-if="loading" class="card_container skeleton">
      <div class="image_section skeleton_image"></div>
      <div class="content_section">
        <h3 class="skeleton_text skeleton_title"></h3>
        <p class="skeleton_text skeleton_description"></p>
        <div class="btn_section">
          <div class="skeleton_btn"></div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!hasArticles" class="no_articles_message">
        <p>No articles available at the moment.</p>
      </div>
      <div v-else class="card_container">
        <div class="image_section">
          <img :src="`https://api.landstory-pl.com/${card.images[0].image_Path}`" alt="">
        </div>
        <div class="content_section">
          <h3>{{ card.title }}</h3>
          <p>{{ card.description }}</p>
          <div class="btn_section">
            <router-link class="card_btn" :to="`article/${card.id}`">Show Article</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticlesCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    hasArticles() {
      return this.card && Object.keys(this.card).length > 0;
    }
  },
  created() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }
};
</script>

<style scoped>
.card_container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 450px;
  background-color: #181A1B;
  margin: 5px 15px;
  border-radius: 25px;
}

.card_container .image_section {
  width: 42%;
  height: 102%;
  border-radius: 15px;
}

.image_section img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.card_container .content_section {
  width: 58%;
  height: 100%;
  padding: 35px;
  text-align: start;
}

.content_section h3 {
  font-size: 22px;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content_section p {
  font-size: 18px;
  overflow-y: auto;
  height: 300px;
  color: white;
  padding: 25px 0;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content_section .btn_section {
  width: 100%;
  text-align: end;
  margin: 20px 0;
}

.btn_section .card_btn {
  padding: 10px 25px;
  background-color: gray;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.btn_section .card_btn:hover {
  background-color: rgb(87, 87, 87);
}

.content_section p::-webkit-scrollbar {
  width: 2px;
}

.content_section p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255);
  border-radius: 10px;
}

.content_section p::-webkit-scrollbar-thumb {
  background: #ff2f2f50;
  border-radius: 10px;
}

.content_section p::-webkit-scrollbar-thumb:hover {
  background: #ff2f2f7a;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .card_container {
    height: 250px;
  }

  .card_container .content_section {
    padding: 35px 15px;
  }

  .content_section h3 {
    font-size: 16px;
  }

  .content_section p {
    font-size: 12px;
    height: 150px;
  }

  .content_section .btn_section {
    margin: 12px 0;
  }

  .btn_section .card_btn {
    padding: 5px 10px;
    font-size: 12px;
  }
}

.skeleton {
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton_image {
  height: 450px;
  background-color: #ddd;
  border-radius: 15px;
}

.skeleton_text {
  background-color: #ddd;
  border-radius: 4px;
  margin: 8px 0;
}

.skeleton_title {
  width: 70%;
  height: 24px;
}

.skeleton_description {
  width: 90%;
  height: 16px;
}

.skeleton_btn {
  width: 100px;
  height: 32px;
  background-color: #ddd;
  border-radius: 4px;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

.no_articles_message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  background-color: #181A1B;
  border-radius: 25px;
  color: white;
  font-size: 18px;
  text-align: center;
}
</style>