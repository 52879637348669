<template>
  <div class="footer_container">
    <div class="logo">
      <div class="logo_section">
        <img src="@/assets/12.png" alt="logo" class="logo-img" />
        <h2>Land Story</h2>
        <h3>We tell you the land story since 1948</h3>
      </div>
      <div class="bio_section">
        <p>
          "Land Story" is a personal, non-profit, and independent endeavor by
          its creators, not affiliated with any organization or entity. The
          website operates under the Creative Commons policy, allowing everyone
          to use and share the content, provided that proper credit is given to
          the website and the content is not used for commercial purposes. We
          aim for Land Story to be an objective reference, offering quick and
          reliable information for those interested in understanding the truth
          about the Israeli-Palestinian human issue. The website contains a
          collection of texts and is still under development. We welcome your
          comments and suggestions at the following address: info@landstory.com
        </p>
      </div>
    </div>
    <div class="social">
      <i class="fa-brands fa-facebook"></i>
      <i class="fa-brands fa-instagram"></i>
      <i class="fa-brands fa-telegram"></i>
    </div>
    <div class="copyright">
      <p>
        CopyRight &copy; 2024 - All right reserved by Hayder Ali & Waeel
        Mohammed
      </p>
      <p>v 1.0.0</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.footer_container {
  width: 100%;
  height: 400px;
  background-color: #262729;
  border-radius: 15px 15px 0 0;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
}

.footer_container .social {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b94242;
  font-size: 28px;
  padding: 15px 0;
  gap: 25px;
}

.footer_container .logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 35px;
  color: white;
  padding: 15px 0;
}

.footer_container .logo_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 15px 0;
  text-align: center;
}

.footer_container .logo .bio_section {
  text-align: start;
  width: 700px;
  font-size: 18px;
}
.footer_container .logo img {
  height: 150px;
}

.footer_container .copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #787878;
  text-align: center;
}

@media (max-width: 500px) {
  .footer_container {
  height: max-content;
}
  .footer_container .logo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .footer_container .logo .bio_section {
    text-align: center;
    width: 100%;
  }
  .footer_container .logo img {
  height: 150px;
}
}
</style>
