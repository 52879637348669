<template>
  <div class="container">
    <h1>{{ article.title }}</h1>
    <hr style="border: 3px solid #B94242; width: 30%; margin: 5px auto 25px auto">
    <div class="gallery_container">
      <div class="article_info">
        <div class="article_auther">
          <p>Author : {{ article.fullName }}</p>
          <p>Date of Publication : {{ formattedDate }}</p>
          <p>Category : {{ article.cat_Name }}</p>
        </div>
        <p class="article_content">
          {{ article.description }}
        </p>
      </div>
      <div class="article_image">
        <Carousel
          id="gallery"
          :items-to-show="1"
          :wrap-around="false"
          v-model="currentSlide"
        >
          <Slide v-for="image in article.images" :key="image.id">
            <div class="carousel__item">
              <img
                class="main_img"
                :src="`https://api.landstory-pl.com/${image.image_Path}`"
                :alt="`${image.id}`"
              />
            </div>
          </Slide>
        </Carousel>
        <Carousel
          id="thumbnails"
          :items-to-show="4"
          :wrap-around="true"
          v-model="currentSlide"
          ref="carousel"
        >
          <Slide v-for="image in article.images" :key="image.id">
            <div class="carousel__item" @click="slideTo(image.id - 2)">
              <img
                class="gallery_img"
                :src="`https://api.landstory-pl.com/${image.image_Path}`"
                :alt="`${image.id}`"
              />
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import axiosApi from "../axios";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "SingleArticle",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      currentSlide: 0,
      article: {},
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    formattedDate() {
      if (!this.article.pub_Date) return "";
      const date = new Date(this.article.pub_Date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
    async fetchData() {
      try {
        const id = this.$route.params.id;
        console.log("Fetching article with id:", id);
        const response = await axiosApi.get(`/Articles/${id}`);
        this.article = response.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
  },
});
</script>

<style scoped>
.container {
  width: 90%;
  margin: 15px auto;
}

.container h1 {
  text-align: center;
  color: #fff;
  padding: 10px 25px;
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.main_img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: fill;
}

.gallery_container {
  display: flex;
  justify-content: space-around;
  align-items: start;
}

.gallery_container .article_image {
  width: 40%;
}

.gallery_img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.gallery_container .article_info {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #fff;
}

.article_info .article_auther {
  background-color: #262729;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
}

.article_info .article_content {
  padding: 15px;
}

@media(max-width:500px){
  .gallery_container {
  flex-direction: column;
}
.gallery_container .article_image {
  width: 100%;
}
}
</style>
