<template>
  <div class="card_article" v-for="company in companies" :key="company.id">
    <div class="img_section">
      <img :src="`https://api.landstory-pl.com/${company.image_Path}`" alt="">
    </div>
    <div class="content_section">
      <div class="text_styles">
        <h3>{{ company.nameCompany }}</h3>
      </div>
      <!-- <div class="btn_styles">
        <router-link :to="`/company/${article.id}`">Read Article</router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticlesCardPrime',
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.card_article {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 32%;
  padding: 10px 5px;
  gap: 10px;
  background-color: #262729;
  color:#fff;
  border-radius: 15px;
  margin: 0 auto;
}
.card_article .img_section{
  width: 100%;
  height: 250px;
}
.img_section img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card_article .content_section{
  padding: 10px 20px;
}
.content_section .text_styles h3{
  font-size: 28px;
}
.content_section .text_styles p{
  color: #c7c7c7;
  margin:10px 0 5px 0;
  height: 100px;
  overflow: hidden;
}
.content_section .btn_styles{
  width: 100%;
  padding: 10px 0;
  text-align: end;
}
.btn_styles a{
  text-decoration: none;
  background-color: #b94242;
  color: white;
  padding: 10px 15px;
  transition: border-radius 0.5s ease-in-out;
  border-radius: 2px;
}
.btn_styles a:hover{
  background-color: #992f2f;
  border-radius: 10px;
  transition: border-radius 0.5s ease-in-out;
}

@media(max-width:500px){
  .card_article {
  width: 100%;
}
}
</style>