import axios from "axios";

const axiosApi = axios.create({
  baseURL:'https://api.landstory-pl.com/api',
  timeout:10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

export default axiosApi;