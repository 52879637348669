<template>
  <div class="card_article" v-for="article in articles" :key="article.id">
    <div class="img_section">
      <img :src="`https://api.landstory-pl.com/${article.images[0].image_Path}`" alt="">
    </div>
    <div class="content_section">
      <div class="text_styles">
        <h3>{{ article.title }}</h3>
        <p>{{ article.description }}</p>
      </div>
      <div class="btn_styles">
        <router-link :to="`/article/${article.id}`">Read Article</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticlesCardPrime',
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped>
.card_article {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 32%;
  height:510px;
  padding: 10px 5px;
  gap: 10px;
  background-color: #262729;
  color:#fff;
  border-radius: 15px;
  margin: 0 auto;
}
.card_article .img_section{
  width: 100%;
  height: 250px;
}
.img_section img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.card_article .content_section{
  padding: 10px 20px;
}
.content_section .text_styles h3{
  font-size: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content_section .text_styles p {
  color: #c7c7c7;
  margin: 10px 0 5px 0;
  height: 92px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Ensure text wraps correctly */
}
.content_section .btn_styles{
  width: 100%;
  padding: 10px 0;
  text-align: end;
}
.btn_styles a{
  text-decoration: none;
  background-color: #b94242;
  color: white;
  padding: 10px 15px;
  transition: border-radius 0.5s ease-in-out;
  border-radius: 2px;
}
.btn_styles a:hover{
  background-color: #992f2f;
  border-radius: 10px;
  transition: border-radius 0.5s ease-in-out;
}

@media(max-width:500px){
  .card_article {
  width: 100%;
}
}
</style>