<template>
  <Carousel
    :autoplay="7000"
    :breakpoints="breakpoints"
    :wrapAround="true"
    :transition="500"
  >
    <Slide v-for="user in WeAre" :key="user.id">
      <div class="user_card">
        <img :src="`https://api.landstory-pl.com/${user.image_Path}`" :alt="user.namePerson" />
        <h4>{{user.namePerson}}</h4>
        <p>{{ user.title }}</p>
      </div>
    </Slide>

    ...
  </Carousel>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "UserComponent",
  components: {
    Carousel,
    Slide,
  },
  props:{
    WeAre:{
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        400: {
          itemsToShow: 2.5,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.95,
        },
      },
    };
  },
});
</script>

<style scoped>
.carousel__slide {
  padding: 25px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}

.user_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user_card img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
}

.user_card h4 {
  color: white;
  font-size: 28px;
  padding: 10px;
}

.user_card p{
  color: #ccc;
  font-weight: 700;
}

@media (max-width: 500px) {
  .user_card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .user_card h4 {
    color: white;
    font-size: 16px;
    padding: 10px 0;
  }
}
</style>
