<template>
  <div class="home">
    <section class="home_section glass">
      <h2>Last Articles</h2>
      <h3 v-if="articles.length === 0"> There are no articles yet. </h3>
      <ArticlesCarousel v-else :articles="articles"/>
    </section>
    <section id="story" class="home_section">
      <AboutPalestine />
    </section>
    <section class="home_section glass">
      <h2>Who We Are</h2>
      <UserCarousel :WeAre="weAre"/>
    </section>
    <section class="home_section">
      <h2>Boycott Companies</h2>
      <h3 v-if="companies.length === 0"> There are no companies yet. </h3>
      <CompanesCarousel v-else :companies="companies"/>
    </section>
  </div>
</template>

<script>
import ArticlesCarousel from '@/components/ArticlesCarousel.vue';
import AboutPalestine from '@/components/AboutPalestine.vue';
import UserCarousel from '@/components/UserCarousel.vue';
import CompanesCarousel from '@/components/CompanesCarousel.vue';
import axiosApi from '../axios';

export default {
  name: 'HomeView',
  components: {
    ArticlesCarousel,
    AboutPalestine,
    UserCarousel,
    CompanesCarousel
  },
  data() {
    return {
      articles: [],
      weAre: [],
      companies: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axiosApi.get('/HomePage/GetAllHome');
        this.articles = response.data.articles;
        this.weAre = response.data.weAre;
        this.companies = response.data.companies;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
}
</script>


<style scoped>
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .home_section{
    width: 100%;
    margin: 71px auto 0 auto;
    text-align: center;
  }

  .home_section h2{
    font-size: 2.5rem;
    font-weight: 900;
    padding: 15px;
    color: white;
  }

  .home_section h3{
    font-weight: 900;
    padding: 15px;
    color: rgb(190, 190, 190);
  }

  .glass{
    background: #242828;
    box-shadow: 0 4px 30px #1D1F20;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    padding: 10px 0;
  }

  @media (max-width: 500px) {
    .home_section{
    width: 100%;
  }
  .home_section h2{
    font-size: 2rem;
    font-weight: 900;
  }
  }
</style>