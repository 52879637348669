<template>
  <h1>Boycott Companies</h1>
  <div class="articles_container">
    <div class="article">
      <companyCard :companies="companies"/>
    </div>
    <div class="pagination">
      <button @click="prevPage" v-if="currentPage!=1">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" v-if="currentPage !== totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import companyCard from '@/components/CompanyCard.vue'
import axiosApi from '@/axios';

export default {
  name: 'ArticlesView',
  components: {
    companyCard,
  },
  data() {
    return {
      companies: [],
      currentPage: 1,
      companyPerPage: 12,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.companies.length / this.companyPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.companyPerPage;
      const end = start + this.companyPerPage;
      return this.companies.slice(start, end);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axiosApi.get('/Companies');
        this.companies = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
  }
}
</script>

<style scoped>
  h1 {
    text-align: center;
    font-size: 42px;
    font-weight: 600;
    color: white;
    padding: 45px 10px;
  }
  .articles_container {
    padding: 15px 50px;
  }
  .articles_container .article {
    display: flex;
    flex-wrap: wrap;
    gap: 35px 10px;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .pagination button {
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: #B94242;
    outline: none;
    border: none;
    color: white;
    border-radius: 10px;
  }
  .pagination span {
    font-size: 18px;
    color: white;
  }
</style>
