<template>
  <div class="about_section">
    <h3>Land Story</h3>
    <p>Once upon a time, and even until now, for many ages past, humans have lived behind the walls of this land in love and harmony. It became a safe haven, embracing various races and sects. The generosity of this land reflected back upon it, transforming it from a nurturing and generous sanctuary into a stage for destruction and violations. Behind the walls of this land lies a living story that we recount to rescue it from oblivion. And so, we tell you the story of the land.</p>
  </div>
</template>

<script>
export default {
  name:'AboutPalestine',
}
</script>

<style scoped>
  .about_section{
    width: 90%;
    margin: 15px auto;
  }

  .about_section h3{
    color: white;
    font-size: 30px;
    padding: 15px;
  }

  .about_section p{
    color: rgb(216, 216, 216);
    font-size: 20px;
  }
</style>