<template>
  <div class="carousel_section">
    <div class="show_all_link">
      <router-link to="/articles">Show All Articles </router-link>
    </div>
    <Carousel :autoplay="15000" :breakpoints="breakpoints" :wrapAround="true">
      <template v-for="article in articles" :key="article.id">
        <Slide>
          <ArticlesCard :card="article" />
        </Slide>
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";
import ArticlesCard from "@/components/ArticlesCard.vue";

import "vue3-carousel/dist/carousel.css";
export default defineComponent({
  name: "ArticlesCarousel",
  components: {
    Carousel,
    Slide,
    ArticlesCard,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
        },
        // 1024 and up
        1024: {
          itemsToShow: 2,
        },
      },
    };
  },
});
</script>

<style scoped>
.carousel_section {
  width: 90%;
  margin: 50px auto;
}

.show_all_link {
  display: flex;
  justify-content: end;
  padding: 10px 5px;
}

.show_all_link a {
  text-decoration: none;
  color: rgb(91, 195, 255);
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
}

.show_all_link a:hover {
  color: rgb(25, 171, 255);
}

@media (max-width: 500px) {
  .carousel_section {
    width: 100%;
    margin: 20px auto;
  }
  .show_all_link {
    font-size: 14px;
  }
  .show_all_link {
    padding: 5px 10px;
  }

  .show_all_link a {
    font-size: 16px;
  }
}
</style>