<template>
  <nav>
    <div class="links">
      <router-link to="/">Home</router-link>
      <router-link :to="{ path: '/', hash: '#story' }" active-class="" exact-active-class="">Story</router-link>
      <router-link to="/articles">Articles</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  Name:"Navbar",
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
  nav{
    display: flex;
    justify-content: space-between;
    background-color: #262729;
    padding: 7px 25px;
  }

  .links{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
  }

.links a {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  padding: 15px 25px;
}

nav a.router-link-exact-active {
  background-color: #b94242;
  padding: 15px 25px;
  border-radius: 10px;
}
</style>